import styled from '@emotion/styled'
import { motion } from "framer-motion";

export const SingleGameBtnContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
	position: relative;
	border-radius: 0.8rem;
	line-height: 2.5rem;
	font-size: 2rem;
	font-weight: 600;
	
	border: 1px solid #012880;
  background-image: ${props => props.btnColor === 'green' 
    ? `linear-gradient(0deg, #5DEA20 0%, #2FBB18 51%, #56D51D 51%, #B7FA8C 100%)`
    : `linear-gradient(0deg,#89279A 0%,#89279A 51%,#89449A 51%,#89279A 100%)`
  };
  &::before {
    content: "";
    display: block;
    height: 0.25rem;
    position: absolute;
    top: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    background: ${props => props.btnColor === 'green' ? 'white' : '#89279A'};
    border-radius: 22%;
    
    opacity: 0.4;
    background-image: linear-gradient(-270deg,rgba(255,255,255,0.00) 0%,rgba(255,255,255,0.7) 20%,rgba(255,255,255,0.7) 80%,rgba(255,255,255,0.00) 100%);
  }

  &::after {
    content: "";
    display: block;
    height: 0.25rem;
    position: absolute;
    bottom: 0.75rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 7.5rem);
    background: #fff;
    border-radius: 100%;
    
    filter: blur(1px);
    opacity: 0.05;
    background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%);
  }
  /* background: ${props => `url(${props.bg})`};
  width: ${props => props.width};
  height: ${props => props.height};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; */
  animation: btn ${props => props.animate ? 2 : 0}s infinite;
  animation-timing-function: ease-in-out;
  @keyframes btn {
    0% {
      /* opacity: 0; */
      transform: scale(1);
    }
    20% {
      /* opacity: 0.8; */
      transform: scale(1.2);
    }
    40% {
      /* opacity: 0.8; */
      transform: scale(1);
    }
    80% {
      /* opacity: 1; */
      transform: scale(1.2);
    }
    100% {
      /* opacity: 0; */
      transform: scale(1);
    }
  }
`;